import { useEffect, useRef, useState } from 'react'
import { Close, Menu } from '@mui/icons-material'
import React from 'react'
import "./Nav.css"
import { Link } from 'react-scroll';

function Nav() {
    const navRef = useRef();

    const showNavBar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }


    return (
        <>
            <header>
                <a href='/'>
                    <img src='/images/masco-soft-logo-1@2x.png' />
                </a>
                <button className="nav-btn" onClick={showNavBar}>
                    <Menu />
                </button>
                <nav ref={navRef}>
                    <Link to="home" smooth={false} duration={200}>
                        <a href="/#home" onClick={showNavBar}>Home</a>
                    </Link>
                    <Link to="aboutus" smooth={false} duration={200}>
                        <a href="/#aboutus" onClick={showNavBar}>About Us</a>
                    </Link>
                    <Link to="ourservices" smooth={false} duration={200}>
                        <a href="/#ourservices" onClick={showNavBar}>Our Services</a>
                    </Link>
                    <Link to="footer" smooth={false} duration={200}>
                        <button onClick={showNavBar} className='lets-talk'>
                            Let's Talk
                        </button>
                    </Link>
                    <button
                        className="nav-btn nav-close-btn"
                        onClick={showNavBar}>
                        <Close />
                    </button>
                </nav>
            </header>
        </>
    )
}

export default Nav