import { NoEncryption } from '@mui/icons-material';
import { Box, Card, CardContent, CardMedia, Grid, Stack, Typography, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'
import { Link } from 'react-scroll';
import "./Cards.css"

const useStyles = makeStyles({
    card: {
        width: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

function Cards() {
    const classes = useStyles();
    const data = [
        {
            image: "/images/Mask group.jpg",
            heading: "Assessment & Strategy",
            details: "We evaluate your IT needs, helping you develop strategies that support good business decisions and budget management."
        },
        {
            image: "/images/Mask group (1).jpg",
            heading: "Security & Compliance",
            details: "We help you achieve your cyber security goals, preventing malicious threats to your organization that could cause damaging data loss and outages"
        },
        {
            image: "/images/Mask group (2).jpg",
            heading: "Backup & Disaster Recovery",
            details: "We provide backup and recovery solutions that protect your data from unauthorized access, malicious cyber security threats, natural disasters, and accidental user deletion."
        },
        {
            image: "/images/Mask group (3).jpg",
            heading: "Monitoring & Management",
            details: "Our 24x7 computer and network monitoring help keep your systems running smoothly by proactively alerting us and opening trouble tickets for our technicians to pre-emptively resolve issues and avoid downtime."
        },
        {
            image: "/images/Mask group (4).jpg",
            heading: "Help Desk Support",
            details: "Our certified technicians become intimately familar with your IT environment providing friendly accessible support for faster resolutions."
        },
        {
            image: "/images/Mask group (5).jpg",
            heading: "Complete IT Infrastructure",
            details: "We help you select the appropriate hardware, software, and cloud solutions to fit your infrastructure and operational needs."
        },
    ]
    return (
        <>
            <div>
                {data.map((item, index) => {
                    const isEven = index % 2 === 0;
                    return (
                        <>
                            <Stack px={5} py={2} sx={{ display: { xs: "none", md: "block" } }}>
                                <Card className={classes.card} key={index}>
                                    <Grid container>
                                        {isEven ? (
                                            <>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <CardMedia image={item.image} className={classes.media} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <CardContent sx={{ flex: '1 0 auto', alignItems: "center", ml: { xs: 0, md: 10 } }}>
                                                            <Stack sx={{ maxWidth: 500 }}>
                                                                <Typography sx={{
                                                                    fontWeight: "600",
                                                                    fontSize: "34px",
                                                                    lineHeight: "115.5%",
                                                                    color: " #32689D",
                                                                    mt: { xs: 0, md: 10 }
                                                                }}  >
                                                                    {item.heading}
                                                                </Typography>
                                                                <Typography component="div" textAlign={"left"} sx={{
                                                                    fontWeight: "400",
                                                                    mt: 2,
                                                                    fontSize: { md: "18px", sm: "14px" },
                                                                    lineHeight: "163%",
                                                                    letterSpacing: " 0.03em",
                                                                    color: "#6D6D6D"
                                                                }}>
                                                                    {item.details}
                                                                </Typography>
                                                            </Stack>

                                                            <Link to="footer" smooth={false} duration={200}>
                                                                <button className='card-btn' style={{
                                                                    marginTop: "20px",
                                                                    width: "180px",
                                                                    height: "55px",
                                                                    background: "linear-gradient(270deg, #76BBFF 0%, #4087CC 51.69%, #32689D 100%)",
                                                                    boxShadow: "0px 1px 7px 1px rgba(0, 0, 0, 0.13)",
                                                                    borderRadius: "7px",
                                                                    border: "none",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "500",
                                                                    fontSize: "22px",
                                                                    color: " #FFFFFF",

                                                                }} >Connect Us</button>
                                                            </Link>
                                                        </CardContent>
                                                    </Box>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <CardContent sx={{ flex: '1 0 auto', alignItems: "center", ml: { xs: 0, md: 10 } }}>
                                                            <Stack sx={{ maxWidth: 500 }}>
                                                                <Typography sx={{
                                                                    fontWeight: "600",
                                                                    fontSize: "34px",
                                                                    lineHeight: "115.5%",
                                                                    color: " #32689D",
                                                                    mt: { xs: 0, md: 10 }
                                                                }}  >
                                                                    {item.heading}
                                                                </Typography>
                                                                <Typography component="div" textAlign={"left"} sx={{
                                                                    fontWeight: "400",
                                                                    mt: 2,
                                                                    fontSize: "18px",
                                                                    lineHeight: "163%",
                                                                    letterSpacing: " 0.03em",
                                                                    color: "#6D6D6D"
                                                                }}>
                                                                    {item.details}
                                                                </Typography>
                                                            </Stack>
                                                            <Link to="footer" smooth={false} duration={200}>
                                                                <button className='card-btn' style={{
                                                                    marginTop: "20px",
                                                                    width: "180px",
                                                                    height: "55px",
                                                                    background: "linear-gradient(270deg, #76BBFF 0%, #4087CC 51.69%, #32689D 100%)",
                                                                    boxShadow: "0px 1px 7px 1px rgba(0, 0, 0, 0.13)",
                                                                    borderRadius: "7px",
                                                                    border: "none",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "500",
                                                                    fontSize: "22px",
                                                                    color: " #FFFFFF",
                                                                    "&:hover": {
                                                                        background: "#efefef"
                                                                    },
                                                                }} >Connect Us</button>
                                                            </Link>
                                                        </CardContent>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <CardMedia image={item.image} className={classes.media} />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Card>
                            </Stack>
                            {/* MOBILE */}
                            <Stack px={5} py={2} sx={{ display: { xs: "block", md: "none" } }}>
                                <Card className={classes.card} key={index}>
                                    <Grid container>
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <CardMedia image={item.image} className={classes.media} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <CardContent sx={{ flex: '1 0 auto', alignItems: "center", ml: { xs: 0, md: 10 } }}>
                                                        <Typography sx={{
                                                            fontWeight: "600",
                                                            fontSize: "18px",
                                                            lineHeight: "115.5%",
                                                            color: " #32689D",
                                                            mt: { xs: 0, md: 10 }
                                                        }}  >
                                                            {item.heading}
                                                        </Typography>
                                                        <Typography component="div" textAlign={"left"} sx={{
                                                            fontWeight: "400",
                                                            mt: 2,
                                                            fontSize: "13px",
                                                            lineHeight: "163%",
                                                            letterSpacing: " 0.03em",
                                                            color: "#6D6D6D"
                                                        }}>
                                                            {item.details}
                                                        </Typography>
                                                        <Link to="footer" smooth={false} duration={200}>
                                                            <button style={{
                                                                marginTop: "20px",
                                                                width: "130px",
                                                                height: "35px",
                                                                background: "linear-gradient(270deg, #76BBFF 0%, #4087CC 51.69%, #32689D 100%)",
                                                                boxShadow: "0px 1px 7px 1px rgba(0, 0, 0, 0.13)",
                                                                borderRadius: "7px",
                                                                border: "none",
                                                                fontStyle: "normal",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                color: " #FFFFFF",
                                                                "&:hover": {
                                                                    background: "#efefef"
                                                                },
                                                            }} >Connect Us</button>
                                                        </Link>
                                                    </CardContent>
                                                </Box>
                                            </Grid>
                                        </>

                                    </Grid>
                                </Card>
                            </Stack>
                        </>
                    );
                })}
            </div>
        </>
    )
}

export default Cards