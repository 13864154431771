import { Stack } from '@mui/material'
import React from 'react'
import "./Service.css"
function Service() {
    return (
        <div id="ourservices">
            <Stack sx={{
                display: { xs: "none", md: "block" }
            }}>
                <div className='services'>
                    <div className='details'>
                        <h1>Our Services</h1>
                        <span>We provide proactive, 24x7 remote and onsite support for efficient IT operations and timely resolutions
                            for your IT-related issues and IT skill gaps. Our services helpprovide ongoing IT improvements and
                            ensure that your IT environment is secure while controlling IT experiences</span>
                    </div>
                </div>
            </Stack>

            {/* MOBILE */}
            <div id="ourservices">

                <Stack sx={{ display: { xs: "block", md: "none" } }} p={2} >
                    <Stack alignItems={"center"} justifyContent="center">
                        <Stack alignItems={"center"} justifyContent="center" textAlign={"center"} spacing={1}>
                            <h1 style={{
                                fontWeight: "600",
                                fontSize: "18px",
                                lineHeight: "115.5%",
                                letterSpacing: "0.04em",
                                color: "#4b4b4b",
                            }}>Our Services</h1>
                            <span style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "13px",
                                lineHeight: "182%",
                                letterSpacing: "0.03em",
                                color: "#6d6d6d",
                            }}>We provide proactive, 24x7 remote and onsite support for efficient IT operations and timely resolutions
                                for your IT-related issues and IT skill gaps. Our services helpprovide ongoing IT improvements and
                                ensure that your IT environment is secure while controlling IT experiences</span>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default Service