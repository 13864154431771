import HomePage from './pages/HomePage';
import './App.css';

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
