import { Divider } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useRef, useState } from 'react'
import { Link } from 'react-scroll'
import "./Footer.css"
function Footer() {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    return (
        <div id="footer">
            <Stack sx={{ display: { xs: "none", md: "block" } }} >

                <div className='footer' >
                    <div className='footer-navigation'>
                        <div className='logos'>
                            <div className='logo'>
                                <img src='/images/masco-soft-logo-2@2x.png' />
                            </div>
                            <div className='social'>
                                <div>
                                    <img src='/images/group-21.svg' />
                                </div>
                            </div>
                        </div>

                        <div className='navigation'>
                            <div className='footer-contactRight'>
                                <div className='head'>
                                    <span>Head office:</span>
                                    <p>203 North Anderson driver, Aberdeen</p>
                                    <h1>Scotland, United Kingdom     +44 122 4905472</h1>
                                </div>
                                <div className='corp'>
                                    <span>Corp. office:</span>
                                    <p> Unique world business center Al Karama,</p>
                                    <h1>Dubai +971 585962322</h1>
                                </div>

                            </div>
                            {/*  */}
                            <div className='footer-company' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{

                                ...(hover && { cursor: "pointer" }),
                            }}>
                                <span><h3>COMPANY</h3></span>
                                <Link to="home" smooth={false} duration={200}>
                                    <span>Home</span>
                                </Link>
                                <Link to="aboutus" smooth={false} duration={200}>

                                    <span>About Us</span>
                                </Link>
                                <Link to="ourservices" smooth={false} duration={200}>

                                    <span>Our Services</span>
                                </Link>
                            </div>

                            <div className='footer-help'>
                                <span><h3>
                                    HELP
                                </h3></span>
                                <span>Customer Support</span>
                                <span>Terms & Conditions</span>
                                <span>Privacy Policy</span>
                            </div>

                            <div className='contactLeft'>
                                <div className='location'>
                                    <img src='/images/location-on.svg' />
                                    <div>
                                        <span>
                                            Masco tower, Malappuram,
                                            Kerala, India 676517
                                        </span>
                                    </div>
                                </div>
                                <div className='mail'>
                                    <img src='/images/vector.svg' />
                                    <span>info@mascosoft.com</span>
                                </div>
                                <div className='phone'>
                                    <img src='/images/phone-android.svg' />
                                    <div>
                                        <span>+91 483 277 3333 <br />
                                            +91 483 277 5555</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Divider variant="middle" sx={{ mt: 4, }} />
                    <div className='footer-copyright'>
                        <span>© Copyright 2022, All Rights Reserved by  mascosoft</span>
                    </div>
                </div>
            </Stack>

            {/* mobile */}
            <Stack sx={{ display: { xs: "block", md: "none" } }} p={2}>
                <Stack >
                    <Stack>
                        <Stack spacing={1}>
                            <Stack mt={2} direction={"row"} alignItems="center" justifyContent={"space-between"}>
                                <Stack >
                                    <img width={"200px"} src='/images/masco-soft-logo-2@2x.png' />
                                </Stack>
                                <Stack >
                                    <div >
                                        <img width={"80px"} src='/images/group-21.svg' />
                                    </div>
                                </Stack>
                            </Stack>


                            {/*  */}
                            <Stack mt={2}>
                                <div >
                                    <span style={{
                                        color: "#32689d",
                                        fontSize: "12px",
                                        fontWeight: "500"
                                    }}>Head office:</span>
                                    <p style={
                                        {
                                            fontWeight: "400",
                                            color: "#313131",
                                            fontSize: "13px"
                                        }
                                    }>203 North Anderson driver, Aberdeen</p>
                                    <h1 style={{
                                        fontWeight: "500",
                                        color: "#000000",
                                        fontSize: "12px"
                                    }}>Scotland, United Kingdom  +44 122 4905472</h1>
                                </div>
                                <div>
                                    <span style={{
                                        color: "#32689d",
                                        fontWeight: "500",
                                        fontSize: "12px"
                                    }}>Corp. office:</span>
                                    <p style={
                                        {
                                            fontWeight: "400",
                                            color: "#313131",
                                            fontSize: "13px"
                                        }
                                    }> Unique world business center Al Karama,</p>
                                    <h1 style={{
                                        fontWeight: "500",
                                        color: "#000000",
                                        fontSize: "12px"
                                    }}>Dubai +971 585962322</h1>
                                </div>

                            </Stack>


                        </Stack>

                        {/*  */}
                        <Stack>
                            <Stack mt={2} direction={"row"} justifyContent={"space-between"} mx={2}>
                                <Stack >
                                    <span style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        lineHeight: "18px",
                                        color: "#626262"
                                    }}>COMPANY</span>
                                    <Link to="home" smooth={false} duration={200}>
                                        <span style={{
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            color: "#626262",
                                        }}>Home</span>
                                    </Link>
                                    <Link to="aboutus" smooth={false} duration={200}>
                                        <span style={{
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            color: "#626262",
                                        }}
                                        >About Us</span>
                                    </Link>
                                    <Link to="ourservices" smooth={false} duration={200}>
                                        <span style={{
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            color: "#626262",
                                        }}>Our Services</span>
                                    </Link>
                                </Stack>

                                <Stack >

                                    <span style={{
                                        fontWeight: "600",
                                        fontSize: "15px",
                                        lineHeight: "18px",
                                        color: "#626262"
                                    }}>
                                        HELP
                                    </span>
                                    <Link >

                                        <span style={{
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            color: "#626262",
                                        }}>Customer Support</span>
                                    </Link>
                                    <Link >

                                        <span style={{
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            color: "#626262",
                                        }}>Terms & Conditions</span>
                                    </Link>
                                    <Link >

                                        <span style={{
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            color: "#626262",
                                        }}>Privacy Policy</span>
                                    </Link>
                                </Stack>
                            </Stack>

                            <Stack direction={"row"} alignItems="center" spacing={1} mt={2}>
                                <img width={14} src='/images/location-on.svg' />
                                <div>
                                    <span style={{
                                        fontSize: "12px",
                                        color: "#626262",
                                    }}>
                                        Masco tower, Malappuram,
                                        Kerala, India 676517
                                    </span>
                                </div>
                            </Stack>

                            <Stack direction={"row"} alignItems="center" spacing={1} ml={"2px"}>

                                <img width={12} src='/images/vector.svg' />
                                <span style={{
                                    fontSize: "12px",
                                    color: "#626262",
                                }}>info@mascosoft.com</span>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" spacing={1} >

                                <img width={12} src='/images/phone-android.svg' />

                                <span style={{
                                    fontSize: "12px",
                                    color: "#626262",
                                }}>+91 483 277 3333 ,
                                    +91 483 277 5555</span>
                            </Stack>

                        </Stack>

                    </Stack>
                </Stack>
                <Divider variant="middle" sx={{ mt: 4, }} />

                <Stack direction={"row"} alignItems="center" justifyContent={"center"}>
                    <span style={{
                        color: "#727272",
                        fontSize: "12px",
                    }}>© Copyright 2022, All Rights Reserved by  Mascosoft</span>
                </Stack>
            </Stack>
        </div>
    )
}

export default Footer