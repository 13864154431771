import { Stack } from '@mui/material';
import React from 'react'
import { Link } from 'react-scroll';
import "./Banner.css"
function Banner() {

    const bannerHead = ['Peace of mind.', <br />, 'Proactive managed IT services ', <br />, 'Designed to work for you'];
    return (
        <div id="home">
            <Stack sx={{ display: { xs: "none", md: "block" } }}>

                <div className='bannerMain'>
                    <div className='banner'>
                        <div className='bannerContent'>
                            <div className='bannerHead'>
                                <h3>
                                    {bannerHead}
                                </h3>
                            </div>
                            <div className='bannerdetails'>
                                <p>
                                    We believe the technology supporting your business operations should reflect the specific needs of your company. That's why we offer IT solutions and network support options tailored to meet your specific IT requirements.
                                </p>
                            </div>
                            <div className='bannerButton'>
                                <Link to="ourservices" smooth={false} duration={200}>
                                    <button>
                                        Explore More
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Stack>

            <Stack sx={{ display: { xs: "none", md: "block" } }}>
                <div className='endShadeMain'>
                    <div className='endShadeLeft'>
                        <div className='endShadeLeftFirst'></div>
                        <div className='endShadeLeftSecond'></div>
                        <div className='endShadeLeftThird'></div>
                    </div>
                    <div className='endShadeRight'>
                        <div className='endShadeRightFirst'></div>
                        <div className='endShadeRightSecond'></div>
                        <div className='endShadeRightThird'></div>
                    </div>
                </div>
            </Stack>

            {/* Mobile */}
            <Stack sx={{ display: { xs: "block", md: "none" } }}>
                <Stack sx={{
                    p: 2,
                }} >
                    <h4 style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "21px",
                        lineHeight: "115.5%",
                        textAlign: "center",
                        color: "#32689d"
                    }}>
                        {bannerHead}
                    </h4>
                    <p style={{
                        marginTop: "5px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "12px",
                        color: "#6d6d6d",
                        textAlign: "center"
                    }}>
                        We believe the technology supporting your business operations should reflect the specific needs of your company. That's why we offer IT solutions and network support options tailored to meet your specific IT requirements.
                    </p>

                </Stack>

                <Stack sx={{
                    position: "relative",
                }}>
                    <Link to="ourservices" smooth={false} duration={200} style={{
                        margin: "0 auto", position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-350%)",
                    }}>
                        <button style={{
                            border: "none",
                            width: "130px",
                            height: "40px",
                            fontWeight: "500",
                            fontSize: "12px",
                            color: "#ffffff",
                            lineHeight: "50%",
                            background: "linear-gradient(270deg, #76bbff 0%, #4087cc 51.69%, #32689d 100%)",
                            boxShadow: "0px 1px 7px 1px rgba(0, 0, 0, 0.13)",
                            borderRadius: "25px",
                            textAlign: "center",
                            // position: "absolute",
                        }}>
                            Explore More
                        </button>
                    </Link>
                    <Stack sx={{
                        width: { sm: "400px", xs: "350px" }, margin: "0px auto"
                    }}>
                        <img src='/images/Group 65.png' />
                    </Stack>
                </Stack>
            </Stack>
            {/* 
            <Stack sx={{ display: { xs: "block", md: "none" } }}>
                <div className='endShadeMain'>
                    <div className='endShadeLeft'>
                        <div className='endShadeLeftFirstMob'></div>
                        <div className='endShadeLeftSecondMob'></div>
                        <div className='endShadeLeftThirdMob'></div>
                    </div>
                    <div className='endShadeRight'>
                        <div className='endShadeRightFirstMob'></div>
                        <div className='endShadeRightSecondMob'></div>
                        <div className='endShadeRightThirdMob'></div>
                    </div>
                </div>
            </Stack> */}
        </div>
    )
}

export default Banner