import { Stack } from '@mui/material'
import React from 'react'
import "./AboutUs.css"

function AboutUs() {
    return (
        <div id='aboutus'>
            <Stack sx={{ display: { xs: "none", md: "block" } }}>
                <div className='aboutus'>
                    <div className='header'>
                        <div className='left'>
                            <img src='/images/group-60.svg' />
                        </div>
                        <div className='center'>
                            <h1>About Us</h1>
                            <h4>Company Grow Since 2017</h4>
                        </div>
                        <div className='right'>
                            <img src='/images/group-59.svg' />
                        </div>
                    </div>
                    <div className='content'>
                        <div className='first'>
                            <span>MASCOSOFT is a leading provider of Software Outsourcing services based Scotland UK, MASCOSOFT provides high-quality yet cost-effective software development solutions for startups, small and medium companies around the world. MASCOSOFT's offshore team has expertise across multiple technology domains (including mobile application development) with a proven track record of delivering  on time and within budget.</span>
                        </div>
                        <div className='second'>
                            <span>
                                In MASCOSOFT, we strive to provide our customers with the best possible service.
                                We live by a mantra that says, "We are in this together," and this is what drives us to succeed.
                                We provide software outsourcing and software development services for organizations of any size.
                                With MASCOSOFT, you can be confident that your project will I be delivered on time and within the set budget
                            </span>
                        </div>
                        <div className='third'>
                            <span>We have been in business for 5 years and have the experience to help you with your project needs.
                                Whether it is developing new software or upgrading an existing application,MASCOSOFT has the expertise
                                tog get the job done right. Our team of developers will deliver high-quality solutions tailored specifically for your organization's needs.</span>
                        </div>
                    </div>
                    <div className='about-footer'>
                        <div className='left'>
                            <img src='/images/group-57.svg' />
                        </div>
                        <div className='center'>
                            <h1>M-Cloud Powered by MASCOSOFT</h1>
                            <h4>(Fundamental resources such as storage and processing to full-fledged applications)</h4>
                        </div>
                        <div className='right'>
                            <img src='/images/group-58.svg' />
                        </div>
                    </div>
                </div>
            </Stack>

            {/* MOBILE */}

            <Stack sx={{ display: { xs: "block", md: "none" } }} p={2}>
                <Stack >
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">

                        <img style={{ width: "50px" }} src='/images/group-60.svg' />

                        <div className='center'>
                            <h1 style={{
                                textAlign: "center",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "23px",
                                color: "#4b4b4b",
                            }}>About Us</h1>
                            <h4 style={{
                                textAlign: "center",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#4b4b4b",
                            }}>Company Grow Since 2017</h4>
                        </div>
                        <img style={{ width: "50px" }} src='/images/group-59.svg' />
                    </Stack>

                    <Stack spacing={1} mt={2} sx={{
                        textAlign: "center",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#6d6d6d",
                        lineHeight: "162%",
                        letterSpacing: "0.03em",
                        gap: "20px",
                    }}>
                        <Stack>
                            <span>MASCOSOFT is a leading provider of Software Outsourcing services based Scotland UK, MASCOSOFT provides high-quality yet cost-effective software development solutions for startups, small and medium companies around the world. MASCOSOFT's offshore team has expertise across multiple technology domains (including mobile application development) with a proven track record of delivering  on time and within budget.</span>
                        </Stack>
                        <Stack>
                            <span>
                                In MASCOSOFT, we strive to provide our customers with the best possible service.
                                We live by a mantra that says, "We are in this together," and this is what drives us to succeed.
                                We provide software outsourcing and software development services for organizations of any size.
                                With MASCOSOFT, you can be confident that your project will I be delivered on time and within the set budget
                            </span>
                        </Stack>
                        <Stack>
                            <span>We have been in business for 5 years and have the experience to help you with your project needs.
                                Whether it is developing new software or upgrading an existing application, MASCOSOFT has the expertise
                                tog get the job done right. Our team of developers will deliver high-quality solutions tailored specifically for your organization's needs.</span>
                        </Stack>
                    </Stack>

                    <Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems="center">

                        <img style={{ width: "50px" }} src='/images/group-60.svg' />

                        <div className='center'>
                            <h1 style={{
                                textAlign: "center",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "#4b4b4b",
                            }}>M-Cloud Powered by MASCOSOFT</h1>
                            <h4 style={{
                                textAlign: "center",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "12px",
                                color: "#4b4b4b",
                            }}>(Fundamental resources such as storage and processing to full-fledged applications)</h4>

                        </div>
                        <img style={{ width: "50px" }} src='/images/group-59.svg' />
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}

export default AboutUs