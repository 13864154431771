import React from 'react'
import AboutUs from '../components/aboutus/AboutUs'
import Banner from '../components/banner/Banner'
import Cards from '../components/cards/Cards'
import Footer from '../components/footer/Footer'
import Nav from '../components/header/Nav'
import Navbar from "../components/header/Navbar"
import Service from '../components/ourService/Service'
import WhyChoose from '../components/whychoose/WhyChoose'
import "./HomePage.css"
function HomePage() {
    return (
        <>
            <Nav />
            <div className='main'>
                <Banner />
                <AboutUs />
                <WhyChoose />
                <Service />
                <Cards />
            </div>
            <Footer />
        </>
    )
}

export default HomePage