import { Stack } from '@mui/material'
import React from 'react'
import "./WhyChoose.css"
function WhyChoose() {
    return (
        <>
            <Stack sx={{ display: { xs: "none", md: "block", } }}>

                <div className='whychoose'>
                    <div className='head'>
                        <h1>Why Choose <span>MASCOSOFT</span></h1>
                        <div className='divider'> </div>
                    </div>
                    <div className='content'>
                        <span>
                            MASCOSOFT is a leading provider of Software services based in Scotland, UK. We provide high-quality yet cost-effective software development solutions for startups, small and medium companiesaround the world MASCOSOFT offshore
                            team has expertise across multiple technology domains (including mobile application development) with
                            a proven track record of delivering high-quality yet cost-effective solutions for startups, small and
                            medium companies around the world.
                        </span>
                        <span>
                            Choosing the right software company for your digital transformation needs can be an important decision because it
                            affects the whole business. At MASCOSOFT, we have a stable track record of excellent performing products.
                            We have had the pleasure of serving clients from startup.
                        </span>
                    </div>
                </div>
            </Stack>

            {/* MOBILE */}
            <Stack sx={{ display: { xs: "block", md: "none" } }} p={2}>
                <Stack p={1} sx={{
                    boxSizing: "border-box",
                    border: "1px dashed #4a7aa9",
                    borderRadius: "39px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                    padding: "10px",
                }}>
                    <Stack width={200}>
                        <h1 style={{
                            fontStyle: "normal",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#3e3e3e",
                        }}>Why Choose </h1>
                        <span style={{
                            fontStyle: "normal",
                            textAlign: "center",
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#32689d",
                        }}> MASCOSOFT</span>
                        <div style={{
                            marginTop: "10px",
                            content: "",
                            height: "2px",
                            backgroundImage: "linear-gradient(to right, #f4faff, #32689d, #f4faff)",

                        }}> </div>
                    </Stack>
                    <span style={{
                        textAlign: "center",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: " #6d6d6d",
                        lineHeight: "182%",
                        letterSpacing: "0.03em",
                    }}>
                        MASCOSOFT is a leading provider of Software services based in Scotland, UK. We provide high-quality yet cost-effective software development solutions for startups, small and medium companiesaround the world MASCOSOFT offshore
                        team has expertise across multiple technology domains (including mobile application development) with
                        a proven track record of delivering high-quality yet cost-effective solutions for startups, small and
                        medium companies around the world.
                    </span>
                    <span
                        style={{
                            textAlign: "center",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: " #6d6d6d",
                            lineHeight: "182%",
                            letterSpacing: "0.03em",
                        }}
                    >
                        Choosing the right software company for your digital transformation needs can be an important decision because it
                        affects the whole business. At MASCOSOFT, we have a stable track record of excellent performing products.
                        We have had the pleasure of serving clients from startup.
                    </span>
                </Stack>
            </Stack>
        </>
    )
}

export default WhyChoose